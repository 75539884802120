const question1 = {
    No: 0,
    description: {
        tw: "病人會自行爬下床，床怎麼靠牆?",
        en: "The patient will climb out of bed and how the bed leans against the wall?",
        id: "Pasien akan naik dari tempat tidur dan bagaimana tempat tidur bersandar di dinding?",
        vn: "Bệnh nhân sẽ trèo ra khỏi giường và chiếc giường tựa vào tường như thế nào?"
    },
    answers: [
        {
            answerId: "00",
            answerNum: 0,
            description: {
                tw: "床橫靠牆，並以陪客椅與床邊桌圍住病床。",
                en: "The bed leans against the wall and encloses the bed with a guest chair and bedside table",
                id: "Tempat tidur bersandar di dinding dan membungkus tempat tidur dengan kursi tamu dan meja di samping tempat tidu",
                vn: "Giường tựa vào tường và bao quanh giường với ghế khách và bàn cạnh giường ngủ"
            },
            answerText: 'A',
            buttonText: 'A',
            isAnswer: true,
            img: "1-1"
        },
        {
            answerId: "01",
            answerNum: 1,
            description: {
                tw: "床與陪客椅並排。",
                en: "The bed is side by side with the guest chair.",
                id: "Tempat tidurnya dipagari dengan kursi tamu",
                vn: "Giường xếp với ghế khách"
            },
            answerText: 'B',
            buttonText: 'B',
            isAnswer: false,
            img: "1-2"
        },
        {
            answerId: "02",
            answerNum: 2,
            description: {
                tw: "床靠邊與陪伴椅平行。",
                en: "The bed is parallel to the accompanying chair",
                id: "Tempat tidur sejajar dengan kursi pendamping",
                vn: "Giường nằm song song với ghế đồng hành"
            },
            answerText: 'C',
            buttonText: 'C',
            isAnswer: false,
            img: "1-3"
        }
    ]
}

const question2 = {
    No: 1,
    description: {
        tw: "輪椅怎麼固定?",
        en: "How to fix the wheelchair?",
        id: "Cara memperbaiki kursi roda",
        vn: "Cách sửa xe lăn"
    },
    answers: [
        {
            answerId: "11",
            answerNum: 1,
            description: {
                tw: "腳踏板收起，但輪椅無煞車。",
                en: "The pedals are put away, but the wheelchair is innocent.",
                id: "Pedal disimpan dan memiliki rem",
                vn: "Bàn đạp được xếp gọn và có phanh"
            },
            answerText: 'A',
            buttonText: 'A',
            isAnswer: false,
            img: "2-2"
        },
        {
            answerId: "10",
            answerNum: 0,
            description: {
                tw: "腳踏板確實收起，煞車確實固定。",
                en: "The pedals are indeed stowed, the brakes are indeed fixed.",
                id: "Pedal disimpan dan memiliki rem",
                vn: "Bàn đạp được xếp gọn và có phanh"
            },
            answerText: 'B',
            buttonText: 'B',
            isAnswer: true,
            img: "2-1"
        },
        {
            answerId: "12",
            answerNum: 2,
            description: {
                tw: "輪椅煞車但腳踏板未收起。",
                en: "Wheelchair brake but the pedals are not folded",
                id: "Rem tetapi sita pedal",
                vn: "Phanh nhưng tịch thu bàn đạp"
            },
            answerText: 'C',
            buttonText: 'C',
            isAnswer: false,
            img: "2-3"
        }
    ]
}

const question3 = {
    No: 2,
    description: {
        tw: "便盆椅怎麼固定?",
        en: "How to fix the potty chair?",
        id: "Cara memperbaiki kursi pispot",
        vn: "Cách sửa chiếc ghế bô"
    },
    answers: [
        {
            answerId: "21",
            answerNum: 1,
            description: {
                tw: "腳踏板未收起。",
                en: "The pedals are not stowed.",
                id: "Pedal tidak dilipat",
                vn: "Bàn đạp không được gấp lại"
            },
            answerText: 'A',
            buttonText: 'A',
            isAnswer: false,
            img: "3-2"
        },
        {
            answerId: "20",
            answerNum: 0,
            description: {
                tw: "腳踏板收起且確實煞車。",
                en: "The pedals are put away and the car is indeed broken.",
                id: "Pedal disimpan dan mobil itu benar-benar rusak",
                vn: "Bàn đạp được đặt đi và chiếc xe thực sự bị hỏng"
            },
            answerText: 'B',
            buttonText: 'B',
            isAnswer: true,
            img: "3-1"
        },
        {
            answerId: "22",
            answerNum: 2,
            description: {
                tw: "便盆椅未煞車。",
                en: "Potty chair does not brake.",
                id: "Kursi potty",
                vn: "Ghế bô"
            },
            answerText: 'C',
            buttonText: 'C',
            isAnswer: false,
            img: "3-3"
        }
    ]
}

const question4 = {
    No: 3,
    description: {
        tw: "尿壺放在哪裡?",
        en: "Where should the urinal placed",
        id: "Di mana urinoir ditempatkan?",
        vn: "Đáy nằm ở đâu?"
    },
    answers: [
        {
            answerId: "30",
            answerNum: 0,
            description: {
                tw: "病人伸手可及處或床旁尿壺架。",
                en: "The place patient can reach or urinal rack near the bed.",
                id: "Tempat urinoir pasien atau rak samping tempat tidur",
                vn: "Bệnh nhân đạt hoặc giá đỡ đầu giường"
            },
            answerText: 'A',
            buttonText: 'A',
            isAnswer: true,
            img: "4-1"
        },
        {
            answerId: "31",
            answerNum: 1,
            description: {
                tw: "陪伴椅上。",
                en: "On the accompanying chair.",
                id: "Kursi yang menyertai",
                vn: "Đi cùng ghế"
            },
            answerText: 'B',
            buttonText: 'B',
            isAnswer: false,
            img: "4-2"
        },
        {
            answerId: "32",
            answerNum: 2,
            description: {
                tw: "病床下方床桿。",
                en: "Under the bed.",
                id: "Tempat tidur di bawah tempat tidur",
                vn: "Giường dưới gầm giường"
            },
            answerText: 'C',
            buttonText: 'C',
            isAnswer: false,
            img: "4-3"
        }
    ]
}

const question5 = {
    No: 4,
    description: {
        tw: "約束帶綁在哪裡?",
        en: "Where should the restraint band tied?",
        id: "Di mana band pengikat terikat?",
        vn: "Ban nhạc kiềm chế được gắn ở đâu?"
    },
    answers: [
        {
            answerId: "41",
            answerNum: 1,
            description: {
                tw: "綁於床欄軸上。",
                en: "Tied to the rod of the bed rail.",
                id: "Terikat ke rel tempat tidur",
                vn: "Gắn vào đường ray giường"
            },
            answerText: 'A',
            buttonText: 'A',
            isAnswer: false,
            img: "5-2"
        },
        {
            answerId: "42",
            answerNum: 2,
            description: {
                tw: "綁於床欄上。",
                en: "Tied to the bed rail.",
                id: "Terikat ke rel tempat tidur",
                vn: "Gắn vào đường ray giường"
            },
            answerText: 'B',
            buttonText: 'B',
            isAnswer: false,
            img: "5-3"
        },
        {
            answerId: "40",
            answerNum: 0,
            description: {
                tw: "綁於床底板上。",
                en: "Tied to the bottom of the bed.",
                id: "Terikat ke bagian bawah tempat tidur",
                vn: "Gắn vào đáy giường"
            },
            answerText: 'C',
            buttonText: 'C',
            isAnswer: true,
            img: "5-1"
        }
    ]
}

const question6 = {
    No: 5,
    description: {
        tw: "臥床病人無人陪伴床欄如何使用?",
        en: "How to use the bedless patient unaccompanied bed rail?",
        id: "Cara menggunakan bed bed tanpa tidur pasien tanpa tempat tidur?",
        vn: "Cách sử dụng giường bệnh không có giường bệnh nhân?"
    },
    answers: [

        {
            answerId: "51",
            answerNum: 1,
            description: {
                tw: "床欄未拉起或未確實收起。",
                en: "The bed rail is not pulled up or is not actually stowed.",
                id: "Rel tempat tidur tidak ditarik atau tidak benar-benar disimpan",
                vn: "Đường ray giường không được kéo lên hoặc không thực sự được xếp gọn"
            },
            answerText: 'A',
            buttonText: 'A',
            isAnswer: false,
            img: "6-2"
        },
        {
            answerId: "50",
            answerNum: 0,
            description: {
                tw: "雙側床欄確實拉起。",
                en: "The double bed rails are indeed pulled up.",
                id: "Bilah tempat tidur ganda tidak menarik",
                vn: "Thanh giường đôi không kéo lên"
            },
            answerText: 'B',
            buttonText: 'B',
            isAnswer: true,
            img: "6-1"
        },
        {
            answerId: "52",
            answerNum: 2,
            description: {
                tw: "床欄未拉起或未確實收起。",
                en: "The bed rail was not pulled up or was not actually stowed.",
                id: "Rel tempat tidur tidak ditarik atau tidak benar-benar disimpan",
                vn: "Đường ray giường không được kéo lên hoặc không thực sự được xếp gọn"
            },
            answerText: 'C',
            buttonText: 'C',
            isAnswer: false,
            img: "6-3"
        }
    ]
}

const question7 = {
    No: 6,
    description: {
        tw: "呼叫鈴放在哪裡?",
        en: "Where should the call bell placed?",
        id: "Di mana lonceng panggilan?",
        vn: "Chuông gọi ở đâu?"
    },
    answers: [
        {
            answerId: "60",
            answerNum: 0,
            description: {
                tw: "置於病人伸手可及處。",
                en: "Place the patient within reach.",
                id: "Ditempatkan pada jangkauan pasien",
                vn: "Được đặt ở phạm vi tiếp cận của bệnh nhân"
            },
            answerText: 'A',
            buttonText: 'A',
            isAnswer: true,
            img: "7-1"
        },
        {
            answerId: "61",
            answerNum: 1,
            description: {
                tw: "不在床上時未將呼叫鈴置於手邊。",
                en: "Did not put the call bell at hand when not in bed.",
                id: "Tidak meletakkan bel panggilan di tangan ketika tidak di tempat tidur",
                vn: "Đã không đặt chuông cuộc gọi ở bàn tay khi không nằm trên giường"
            },
            answerText: 'B',
            buttonText: 'B',
            isAnswer: false,
            img: "7-2"
        },
        {
            answerId: "62",
            answerNum: 2,
            description: {
                tw: "於床上時未將呼叫鈴取下置於手邊。",
                en: "The call bell was not removed at the time of the bed.",
                id: "Lonceng panggilan tidak dihapus pada saat tempat tidur",
                vn: "Chuông cuộc gọi không được xóa tại thời điểm giường"
            },
            answerText: 'C',
            buttonText: 'C',
            isAnswer: false,
            img: "7-3"
        }
    ]
}

const question8 = {
    No: 7,
    description: {
        tw: "助行器怎麼使用及固定?",
        en: "How to use and fix the walker?",
        id: "Cara menggunakan dan memperbaiki walker",
        vn: "Cách sử dụng và sửa chữa khung tập đi"
    },
    answers: [
        {
            answerId: "71",
            answerNum: 1,
            description: {
                tw: "助行器未採合適高度身體呈現屈曲。",
                en: "The walker does not adopt a suitable height to present the buckling.",
                id: "Walker tidak mengadopsi ketinggian yang sesuai untuk mempresentasikan tekuk",
                vn: "Các khung tập đi không áp dụng một chiều cao thích hợp để trình bày các buckling"
            },
            answerText: 'A',
            buttonText: 'A',
            isAnswer: false,
            img: "8-2"
        },
        {
            answerId: "72",
            answerNum: 2,
            description: {
                tw: "助行器未固定卡榫。",
                en: "Walker is not fixed.",
                id: "Walker tidak diperbaiki",
                vn: "Walker không cố định"
            },
            answerText: 'B',
            buttonText: 'B',
            isAnswer: false,
            img: "8-3"
        },
        {
            answerId: "70",
            answerNum: 0,
            description: {
                tw: "卡榫確實固定，調整合適高度雙手握住助行器，手肘會自然彎曲成15-30度。",
                en: "The cassette is fixed, adjust the height to hold the walker with both hands, and the elbow will naturally bend to 15-30 degrees.",
                id: "Kaset diperbaiki, atur tinggi untuk menahan walker dengan kedua tangan, dan siku secara alami akan menekuk hingga 15-30 derajat.",
                vn: "Băng cassette được cố định, điều chỉnh chiều cao để giữ khung tập đi bằng cả hai tay, và khuỷu tay sẽ tự nhiên uốn cong đến 15-30 độ."
            },
            answerText: 'C',
            buttonText: 'C',
            isAnswer: true,
            img: "8-1"
        }
    ]
}

const question9 = {
    No: 8,
    description: {
        tw: "床旁電話怎麼撥打找到護理師?",
        en: "How to call the bedside phone to find a nurse?",
        id: "Bagaimana Anda menelepon telepon di samping tempat tidur untuk mencari perawat?",
        vn: "Làm thế nào để bạn gọi điện thoại cạnh giường để tìm một y tá?"
    },
    answers: [
        {
            answerId: "81",
            answerNum: 1,
            description: {
                tw: "51",
                en: "51",
                id: "51",
                vn: "51"
            },
            answerText: 'A',
            buttonText: 'A',
            isAnswer: false,
            img: "9-2"
        },
        {
            answerId: "82",
            answerNum: 2,
            description: {
                tw: "115",
                en: "115",
                id: "115",
                vn: "115"
            },
            answerText: 'B',
            buttonText: 'B',
            isAnswer: false,
            img: "9-3"
        },
        {
            answerId: "80",
            answerNum: 0,
            description: {
                tw: "15",
                en: "15",
                id: "15",
                vn: "15"
            },
            answerText: 'C',
            buttonText: 'C',
            isAnswer: true,
            img: "9-1"
        }
    ]
}


const question10 = {
  No: 9,
  description: {
    tw:"如何採漸進式下床？",
    en:"How to get progressively out of bed?",
    id:"Bagaimana caranya bangun tidur secara bertahap?",
    vn:"Làm thế nào để có được ra khỏi giường dần dần?"
  },
  answers: [
      {
          answerId: "81",
          answerNum: 1,
          description: {
            tw: "先將床頭搖高 90 度，呈坐姿。",
            en: "First swing the bed 90 degrees high, in a sitting position.",
            id: "Pertama ayunkan tempat tidur 90 derajat tinggi, dalam posisi duduk",
            vn: "Đầu tiên xoay giường 90 độ cao, ở tư thế ngồi"
          },
          answerText: 'A',
          buttonText: 'C > A > B',
          isAnswer: false,
          img: "10-2"
      },
      {
          answerId: "82",
          answerNum: 2,
          description: {
            tw: "扶持下床或坐輪椅，無頭暈現象再步行。",
            en: "Support to get out of bed or in a wheelchair, no dizziness and then walk.",
            id: "Dukungan untuk keluar dari tempat tidur atau di kursi roda, tidak pusing dan kemudian berjalan.",
            vn: "Hỗ trợ để ra khỏi giường hoặc trên xe lăn, không chóng mặt và sau đó đi bộ."
          },
          answerText: 'B',
          buttonText: 'B > C > A',
          isAnswer: false,
          img: "10-3"
      },
      {
          answerId: "80",
          answerNum: 0,
          description: {
            tw: "請病人坐於床緣深呼吸3~5次，雙下肢下垂於床緣，行踢腿活動3~5 分鐘。",
            en: "Ask the patient to take a deep breath 3 to  times at the edge of the bed. The lower limbs hang down on the edge of the bed and kick the leg for 3 to 5 minutes.",
            id: "Mintalah pasien untuk menarik napas dalam-dalam 3 hingga 5 kali di tepi tempat tidur, Anggota tubuh bagian bawah menggantung di tepi tempat tidur dan menendang kaki selama 3 hingga 5 menit.",
            vn: "Yêu cầu bệnh nhân hít thở sâu 3 đến 5 lần ở rìa giường, tay chân dưới treo trên mép giường và đạp chân trong 3 đến 5 phút."
          },
          answerText: 'C',
          buttonText: 'A > C > B',
          isAnswer: true,
          img: "10-1"
      }
  ]
}


export const Questions = {
    question1,
    question2,
    question3,
    question4,
    question5,
    question6,
    question7,
    question8,
    question9,
    question10,
}

export const question1033 = [
    {
        answerId: "90",
        answerNum: 0,
        description: {
            tw: "先將床頭搖高 90 度，呈坐姿。",
            en: "First swing the bed 90 degrees high, in a sitting position.",
            id: "Pertama ayunkan tempat tidur 90 derajat tinggi, dalam posisi duduk",
            vn: "Đầu tiên xoay giường 90 độ cao, ở tư thế ngồi"
        },
        isAnswer: 1,
        img: "10-1"
    },
    {
        answerId: "92",
        answerNum: 1,
        description: {
            tw: "扶持下床或坐輪椅，無頭暈現象再步行。",
            en: "Support to get out of bed or in a wheelchair, no dizziness and then walk.",
            id: "Dukungan untuk keluar dari tempat tidur atau di kursi roda, tidak pusing dan kemudian berjalan.",
            vn: "Hỗ trợ để ra khỏi giường hoặc trên xe lăn, không chóng mặt và sau đó đi bộ."
        },
        isAnswer: 2,
        img: "10-3"
    },
    {
        answerId: "91",
        answerNum: 2,
        description: {
            tw: "請病人坐於床緣深呼吸3~5次，雙下肢下垂於床緣，行踢腿活動3~5 分鐘。",
            en: "Ask the patient to take a deep breath 3 to  times at the edge of the bed. The lower limbs hang down on the edge of the bed and kick the leg for 3 to 5 minutes.",
            id: "Mintalah pasien untuk menarik napas dalam-dalam 3 hingga 5 kali di tepi tempat tidur, Anggota tubuh bagian bawah menggantung di tepi tempat tidur dan menendang kaki selama 3 hingga 5 menit.",
            vn: "Yêu cầu bệnh nhân hít thở sâu 3 đến 5 lần ở rìa giường, tay chân dưới treo trên mép giường và đạp chân trong 3 đến 5 phút."
        },
        isAnswer: 3,
        img: "10-2"
    }
]

export const finalQuestionsAnwser = [1, 3, 2];

export default Questions;
