import React, { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import './style.scss'

export default ({ onTextChange , onLogin, state}) => {
  if(isEmpty(state.noneLogin)) return <Fragment />;

  return (
    <div className="login-box">
      <div className="login-panel">
        <img alt='logo' className="login-logo" src={require("../../assets/images/logo.png")} />
        <h1 className="">蝴蝶歷險記</h1>
        <p className="sub-title">The Advanture of Butterfly</p>
        <h4>預防跌倒連連看</h4>
        <p>題目中會出現與預防跌倒相關的情境，請選擇正確答案並按下確定回答問題。</p>
        <p>請輸入您的床號，選擇您的語言。</p>
        <div className="form-group">
          <div className="input-group">
            <div className="input-group-addon">
              <span className="input-group-text">病室床號</span>
            </div>
            <input type="text" name="bedNo" className="form-control" onChange={onTextChange} />
          </div>
        </div>
        <div className="form-group">
          <div className="input-group">
            <div className="input-group-addon">
              <span className="input-group-text">語  言</span>
            </div>
            <select name="language" className="form-control" onChange={onTextChange}>
              <option value="tw">繁體中文</option>
              <option value="en">English</option>
              <option value="vn">Việt Ngữ</option>
              <option value="id">Bahasa Indonesia</option>
            </select>
          </div>
        </div>
      </div>
      <div className="login-footer">
        <input type="button" value="開始遊戲" className="btn btn-butterfly" onClick={onLogin}/>
      </div>
    </div>
  )
}
