import { fetchPost } from './fetch';

const { REACT_APP_DOMAIN } = process.env;

const getUrl = (url) => `${REACT_APP_DOMAIN}/${url}`;

export const getTokenResult = ({ customHeaders, ...payload }) => {
  return fetchPost(getUrl('auth/token'), customHeaders, payload);
}

export const submitAnswerResult = ({ customHeaders, ...payload }) => {
  return fetchPost(getUrl('answer'), customHeaders, payload);
}
