import React from "react";
import { useMachine } from "@xstate/react";
import machines from "./fsm/machines";
import Exam from "./views/Exam";
import Login from "./views/Login";
import types from "./constants/actionTypes";
import "./App.css";
import "./theme/variable.scss";
import Dialog from './components/Dialog';

function App() {
  const [state, send] = useMachine(machines);

  const onLogin = () => {
    send(types.LOGIN);
  };

  const onNextClick = () => {
    send(types.NEXT);
  };

  const onTextChange = (event) => {
    const {
      target: { name, value },
    } = event;
    send(types.CHANGE, { name, value });
  };

  const selectAnswer = (isAnswer) => () => {
    send(types.CHECK, { isAnswer });
  };

  const onLogoutClick = () => {
    send(types.LOGOUT);
  };

  const closeDialog = () => {
    send(types.CLOSE);
  }

  return (
    <div className='App'>
      <Login
        state={state.value.main}
        onTextChange={onTextChange}
        onLogin={onLogin}
      />
      <Exam
        state={state.value.main}
        context={state.context}
        selectAnswer={selectAnswer}
        onNextClick={onNextClick}
        onLogoutClick={onLogoutClick}
      />
      <Dialog errorMessage={state.context.errorMessage} closeDialog={closeDialog}/>
      {/* <span>{JSON.stringify(state.value)}</span>
      <span>{state.context.errorMessage}</span>
      <button onClick={onLogin}>Go Action</button> */}
    </div>
  );
}

export default App;
