import React, { Fragment } from "react";
import isEmpty from "lodash/isEmpty";

export default ({ errorMessage, closeDialog }) => {
  if (isEmpty(errorMessage)) return <Fragment />;

  return (
    <Fragment>
      <div className='mask' onClick={closeDialog}></div>
      <div className='dialog-panel'>
        <div className='dialog-message'>{errorMessage}</div>
        <div className='btn btn-primary' onClick={closeDialog}>
          關閉
        </div>
      </div>
    </Fragment>
  );
};
