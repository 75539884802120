import QS from "query-string";
import isEmpty from "lodash/isEmpty";

const defaultHeaders = {
  "Content-Type": "application/json",
};

const getResponseCode = (responseOK, statusCode, bodyResult) => {
  if (responseOK && statusCode >= 200 && statusCode < 300) {
    return bodyResult.success;
  }
  return false;
};

const parseResponse = (response) => {
  const { status: statusCode, ok: responseOK } = response;

  return response.text().then((body) => {
    const bodyResult = isEmpty(body) ? {} : JSON.parse(body);
    const ok = getResponseCode(responseOK, statusCode, bodyResult);
    const result = { ...bodyResult };
    if (!ok) throw result.data;
    return {
      statusCode,
      ok,
      result,
    };
  });
};

const parseInternetError = (error) => {
  if (error.message.includes("Failed to fetch")) {
    const result = { code: "500", message: "服务异常，请重新再试或与客服联系" };
    throw result;
  }
  throw error;
};

export const fetchPost = (
  url,
  customHeaders,
  payload = {},
  method = "POST"
) => {
  return fetch(url, {
    method,
    headers: { ...defaultHeaders, ...customHeaders },
    body: JSON.stringify(payload),
  })
    .then(parseResponse)
    .catch(parseInternetError);
};

export const fetchGet = (url, customHeaders, payload = {}) => {
  const realUrl =
    Object.keys(payload).length === 0 ? url : `${url}?${QS.stringify(payload)}`;

  return fetch(realUrl, {
    method: 'GET',
    headers: { ...defaultHeaders, ...customHeaders },
    body: JSON.stringify(payload),
  })
    .then(parseResponse)
    .catch(parseInternetError);
};
