import React, { Fragment } from "react";
import questionConfig from "../../../constants/exam.config";

const CORRECT_TEXT = {
  tw: "恭喜答對!",
  en: "Correct !",
  id: "Benar !",
  vn: "Đúng !",
};

const FAIL_TEXT = {
  tw: "錯誤了！跌倒！",
  en: "Wrong! Falling",
  id: "Salah, Jatuh",
  vn: "Sai rồi! rơi xuống",
};

const RESULT_TEXT = {
  tw: "答對",
  en: "Score",
  id: "Score",
  vn: "Score",
};

const getBtnText = (isLastQuestion) => (isLastQuestion ? "看結果" : "下一題");

const Answers = ({ show, currentQuestion, selectAnswer, language }) => {
  if (!show) return <Fragment />;

  return (
    <div className='answer-row'>
      {currentQuestion.answers.map((item) => (
        <div key={`question_${item.img}`} className='question-box'>
          <div className='questionNo'>{item.answerText}</div>
          <div className={`question-${item.img}`}></div>
          <div className='question-description'>
            <p>{item.description[language]}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const ClickButtons = ({ show, currentQuestion, selectAnswer, ...props }) => {
  if (!show) return <Fragment />;

  return (
    <div className="answers-button-box">
      <div className='select-answer'>請選擇正確答案</div>
      <div className='button-row'>
        {currentQuestion.answers.map((item) => (
          <div className='answer-button' onClick={selectAnswer(item.isAnswer)}>
            {item.buttonText}
          </div>
        ))}
      </div>
    </div>
  );
};

const QuestionDescription = ({ show, currentQuestion, language }) => {
  if (!show) return <Fragment />;
  return (
    <h3>
      {`${currentQuestion.No + 1}. ${currentQuestion.description[language]}`}
    </h3>
  );
};

const FailedResult = ({ show, onNextClick, language, isLastQuestion }) => {
  if (!show) return <Fragment />;

  return (
    <Fragment>
      <div className='fail-image'></div>
      <h4>{FAIL_TEXT[language]}</h4>
      <input
        type='button'
        value={getBtnText(isLastQuestion)}
        className='answer-button'
        onClick={onNextClick}
      />
    </Fragment>
  );
};

const SuccessResult = ({ show, onNextClick, language, isLastQuestion }) => {
  if (!show) return <Fragment />;

  return (
    <Fragment>
      <div className='success-image'></div>
      <h4>{CORRECT_TEXT[language]}</h4>
      <input
        type='button'
        value={getBtnText(isLastQuestion)}
        className='answer-button'
        onClick={onNextClick}
      />
    </Fragment>
  );
};

const FinalResult = ({ show, score, language, onNextClick, onLogoutClick }) => {
  if (!show) return <Fragment />;

  return (
    <Fragment>
      <h2>{`${RESULT_TEXT[language]} ${score}/10`}</h2>
      <input
        type='button'
        value='再玩一次'
        className='answer-button'
        onClick={onNextClick}
      />
      <input
        type='button'
        value='結束'
        className='answer-button'
        onClick={onLogoutClick}
      />
    </Fragment>
  );
};

export default ({
  questionNo,
  language,
  selectAnswer,
  onNextClick,
  onLogoutClick,
  score,
}) => {
  const questionKey = Object.keys(questionNo)[0];
  const answerState = questionNo[questionKey];
  const currentQuestion = questionConfig[questionKey];
  const isLastQuestion = questionKey === "question10";

  return (
    <div>
      <QuestionDescription
        show={answerState === "idle"}
        language={language}
        currentQuestion={currentQuestion}
      />
      <Answers
        show={answerState === "idle"}
        language={language}
        currentQuestion={currentQuestion}
      />
      <ClickButtons
        show={answerState === "idle"}
        selectAnswer={selectAnswer}
        currentQuestion={currentQuestion}
      />
      <FailedResult
        show={answerState === "failure"}
        language={language}
        onNextClick={onNextClick}
        isLastQuestion={isLastQuestion}
      />
      <SuccessResult
        show={answerState === "correct"}
        language={language}
        onNextClick={onNextClick}
        isLastQuestion={isLastQuestion}
      />
      <FinalResult
        language={language}
        show={questionNo === "sended"}
        score={score}
        onNextClick={onNextClick}
        onLogoutClick={onLogoutClick}
      />
    </div>
  );
};
