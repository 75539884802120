import React from 'react';

const BED_TEXT = {
    tw:"病室床號 :",
    en:"Bed No. :",
    id:"Nomor tempat tidur :",
    vn:"Số giường :"
}

const POINTS = {
    tw:"分",
    en:"point",
    id:"point",
    vn:"point"
}

export default ({ score, bedNo, language }) => {
    return (
        <div className="header">
            <span>{`${BED_TEXT[language]}${bedNo}`}</span>
            <span  className="question-score">{`${score}${POINTS[language]}`}</span>
        </div>
    )
}
