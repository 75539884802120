import React, { Fragment } from "react";
import isEmpty from "lodash/isEmpty";
import ScoreHeader from "./components/ScoreHeader";
import "./styles.scss";
import QuestionBox from "./components/QuestionBox";

const getScore = (context) => {
  return context.answers.reduce((sum, score) => sum + score, 0);
};

const Exam = ({ onNextClick, onLogoutClick, selectAnswer, context, state }) => {
  if (isEmpty(state.hasLogin)) return <Fragment />;

  return (
    <div className='exam-container'>
      <ScoreHeader
        score={getScore(context)}
        bedNo={context.bedNo}
        language={context.language}
        />
      <QuestionBox
        score={getScore(context)}
        language={context.language}
        questionNo={state.hasLogin}
        selectAnswer={selectAnswer}
        onNextClick={onNextClick}
        onLogoutClick={onLogoutClick}
      />
    </div>
  );
};

export default Exam;
