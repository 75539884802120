const TYPES = [
  'LOGIN',
  'LOGIN_SUCCESS',
  'LOGIN_ERROR',
  'LOGOUT',
  'NEXT',
  'CHANGE',
  'CHECK',
  'CLOSE',
]

export default TYPES.reduce((types, key) => {
  types[key] = key;
  return types;
}, {});