import types from "../../constants/actionTypes";
import { Machine, assign } from "xstate";
import { getTokenResult, submitAnswerResult } from "../../apis";


export const machine = Machine(
  {
    id: "machine",
    type: "parallel",
    context: {
      bedNo: "",
      token: "",
      language: "tw",
      answers: [],
      errorMessage: "",
    },
    on: {
      [types.CLOSE]: {
        actions: 'clearMessageAction'
      }
    },
    states: {
      main: {
        initial: "noneLogin",
        states: {
          loginProcessing: {
            invoke: [
              {
                id: "loginService",
                src: "loginService",
                onDone: { target: "hasLogin", actions: "setLoginInfoAction" },
                onError: { target: "noneLogin", actions: "setErrorMessage" },
              },
            ],
          },
          hasLogin: {
            initial: "question1",
            on: {
              [types.LOGOUT]: {
                target: "noneLogin",
                actions: "logoutAction"
              }
            },
            states: {
              question1: {
                initial: 'idle',
                on: {
                  [types.NEXT]: "question2",
                },
                states: {
                  idle: {
                    on: {
                      [types.CHECK]: [
                        {
                          target: "correct",
                          actions: "answerCorrect",
                          cond: 'checkAnswer'
                        },
                        {
                          target: "failure",
                          actions: "answerFailed",
                        }
                      ]
                    }
                  },
                  correct: {},
                  failure: {}
                }
              },
              question2: {
                initial: 'idle',
                on: {
                  [types.NEXT]: "question3",
                },
                states: {
                  idle: {
                    on: {
                      [types.CHECK]: [
                        {
                          target: "correct",
                          actions: "answerCorrect",
                          cond: 'checkAnswer'
                        },
                        {
                          target: "failure",
                          actions: "answerFailed",
                        }
                      ]
                    }
                  },
                  correct: {},
                  failure: {}
                }
              },
              question3: {
                initial: 'idle',
                on: {
                  [types.NEXT]: "question4",
                },
                states: {
                  idle: {
                    on: {
                      [types.CHECK]: [
                        {
                          target: "correct",
                          actions: "answerCorrect",
                          cond: 'checkAnswer'
                        },
                        {
                          target: "failure",
                          actions: "answerFailed",
                        }
                      ]
                    }
                  },
                  correct: {},
                  failure: {}
                }
              },
              question4: {
                initial: 'idle',
                on: {
                  [types.NEXT]: "question5",
                },
                states: {
                  idle: {
                    on: {
                      [types.CHECK]: [
                        {
                          target: "correct",
                          actions: "answerCorrect",
                          cond: 'checkAnswer'
                        },
                        {
                          target: "failure",
                          actions: "answerFailed",
                        }
                      ]
                    }
                  },
                  correct: {},
                  failure: {}
                }
              },
              question5: {
                initial: 'idle',
                on: {
                  [types.NEXT]: "question6",
                },
                states: {
                  idle: {
                    on: {
                      [types.CHECK]: [
                        {
                          target: "correct",
                          actions: "answerCorrect",
                          cond: 'checkAnswer'
                        },
                        {
                          target: "failure",
                          actions: "answerFailed",
                        }
                      ]
                    }
                  },
                  correct: {},
                  failure: {}
                }
              },
              question6: {
                initial: 'idle',
                on: {
                  [types.NEXT]: "question7",
                },
                states: {
                  idle: {
                    on: {
                      [types.CHECK]: [
                        {
                          target: "correct",
                          actions: "answerCorrect",
                          cond: 'checkAnswer'
                        },
                        {
                          target: "failure",
                          actions: "answerFailed",
                        }
                      ]
                    }
                  },
                  correct: {},
                  failure: {}
                }
              },
              question7: {
                initial: 'idle',
                on: {
                  [types.NEXT]: "question8",
                },
                states: {
                  idle: {
                    on: {
                      [types.CHECK]: [
                        {
                          target: "correct",
                          actions: "answerCorrect",
                          cond: 'checkAnswer'
                        },
                        {
                          target: "failure",
                          actions: "answerFailed",
                        }
                      ]
                    }
                  },
                  correct: {},
                  failure: {}
                }
              },
              question8: {
                initial: 'idle',
                on: {
                  [types.NEXT]: "question9",
                },
                states: {
                  idle: {
                    on: {
                      [types.CHECK]: [
                        {
                          target: "correct",
                          actions: "answerCorrect",
                          cond: 'checkAnswer'
                        },
                        {
                          target: "failure",
                          actions: "answerFailed",
                        }
                      ]
                    }
                  },
                  correct: {},
                  failure: {}
                }
              },
              question9: {
                initial: 'idle',
                on: {
                  [types.NEXT]: "question10",
                },
                states: {
                  idle: {
                    on: {
                      [types.CHECK]: [
                        {
                          target: "correct",
                          actions: "answerCorrect",
                          cond: 'checkAnswer'
                        },
                        {
                          target: "failure",
                          actions: "answerFailed",
                        }
                      ]
                    }
                  },
                  correct: {},
                  failure: {}
                }
              },
              question10: {
                initial: 'idle',
                on: {
                  [types.NEXT]: "sending",
                },
                states: {
                  idle: {
                    on: {
                      [types.CHECK]: [
                        {
                          target: "correct",
                          actions: "answerCorrect",
                          cond: 'checkAnswer'
                        },
                        {
                          target: "failure",
                          actions: "answerFailed",
                        }
                      ]
                    }
                  },
                  correct: {},
                  failure: {}
                }
              },
              sending: {
                invoke: [
                  {
                    id: "sendResultService",
                    src: "sendResultService",
                    onDone: "sended",
                    onError: { target: "sended", actions: "setErrorMessage" },
                  },
                ],
              },
              sended: {
                on: {
                  [types.NEXT]: {
                    target: "question1",
                    actions: "replayAction"
                  },
                },
              },
            },
          },
          noneLogin: {
            initial: 'editing',
            on: {
              [types.LOGIN]: "loginProcessing",
            },
            states: {
              editing: {
                on: {
                  [types.CHANGE]: {
                    target: '.idle',
                    actions: 'onChangeAction'
                  }
                },
                initial: 'idle',
                states: {
                  idle: {},
                  invalid: {}
                }
              },
            },
          },
        },
      },
    },
    global: {
      
    },
  },
  {
    guards: {
      checkAnswer: (_, { isAnswer }) => {
        return isAnswer;
      }
    },
    actions: {
      logoutAction: assign(() => {
        return {
          bedNo: "",
          token: "",
          language: "tw",
          answers: [],
          errorMessage: "",
        }
      }),
      replayAction: assign(()=> {
        return {
          answers: []
        }
      }),
      clearMessageAction: assign(() => {
        return {
          errorMessage: ""
        }
      }),
      answerFailed: assign(({ answers }, _) => {
        answers.push(0)
        return {
          answers
        }
      }),
      answerCorrect: assign(({ answers }, _) => {
        answers.push(1)
        return {
          answers
        }
      }),
      onChangeAction: assign((_, { name, value }) => {
        return {
          [name]: value
        }
      }),
      setLoginInfoAction: assign((content, { data }) => {
        const { bedNo, token } = data.result.data;
        return {
          bedNo,
          token,
          language: content.language,
        };
      }),
      setErrorMessage: assign((_, event) => {
        return {
          errorMessage: event.data.message,
        };
      }),
    },
    services: {
      loginService: async (content, _) => {
        const { type, ...payload } = content;
        const loginPayload = {
          bedNo: payload.bedNo,
          language: payload.language,
          clientId: process.env.REACT_APP_CLIENT_ID,
        };
        return await getTokenResult(loginPayload);
      },
      sendResultService: async (content, _) => {
        const { bedNo, language, answers } = content;
        const payload = {
          customHeaders: { Authorization: `Bearer ${content.token}` },
          bedNo,
          answers,
          language,
        };
        return await submitAnswerResult(payload);
      },
    },
  }
);

export default machine;
